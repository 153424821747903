import axios from "axios";
import * as SessionManager from "../session/SessionManager";
import * as SessionKeys from "../session/SessionKeys";
import { ACCESS_TOKEN } from "../session/SessionKeys";
import { logData } from "../logs/LogManager";

export const callPostApi = async (
  componentName,
  API_TAG,
  URL,
  bodyParams,
  static_header = false
) => {
  try {
    logData(API_TAG, {
      COMPONENT_NAME: componentName,
      API_URL: URL,
      HEADER: getHeader(static_header),
      BODY: bodyParams,
    });

    let apiResponse = await axios.post(URL, JSON.stringify(bodyParams), {
      headers: getHeader(static_header),
    });

    logData(
      API_TAG,
      JSON.stringify({
        response: apiResponse.data,
      })
    );

    return apiResponse.data;
  } catch (e) {
    logData(
      API_TAG,
      JSON.stringify({
        error: e,
      })
    );

    return e;
  }
};

export const callGetApi = async (componentName, API_TAG, URL) => {
  try {
    logData(API_TAG, {
      COMPONENT_NAME: componentName,
      API_URL: URL,
      HEADER: getHeader(),
      BODY: {},
    });

    let apiResponse = await axios.get(URL, {
      headers: getHeader(),
    });
    logData(
      API_TAG,
      JSON.stringify({
        response: apiResponse.data,
      })
    );

    return apiResponse.data;
  } catch (e) {
    logData(
      API_TAG,
      JSON.stringify({
        error: e,
      })
    );

    return e;
  }
};

export const getHeader = (static_header = false) => {
  // alert(JSON.parse(SessionManager.getSessionData(SessionKeys.LANGUAGE_LOCALE)));

  if (
    SessionManager.getSessionData(ACCESS_TOKEN) === null ||
    SessionManager.getSessionData(ACCESS_TOKEN) === undefined ||
    static_header
  ) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      aliasName: "apporiotaxi",
      // publicKey: "TPSFCtI5xAZO38QisvklmJ6MBoHq2y",
      // secretKey: "WkRoFrstiwK3OnJSmIMfeuND2BjPlY",
      publicKey: "l5oAOHkxN4pm7GRK8FQwDtT1PZr9LS",
      secretKey: "1G28pNEXlmwxrSTRMgtUj3oZe5nsJZ",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,POST,PUT,PATCH,DELETE",
      "Access-Control-Allow-Headers": "application/json",
      locale: SessionManager.getSessionData(SessionKeys.LANGUAGE_LOCALE)
        ? SessionManager.getSessionData(SessionKeys.LANGUAGE_LOCALE)
        : "en",
    };
  } else {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      aliasName: "apporiotaxi",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,POST,PUT,PATCH,DELETE",
      "Access-Control-Allow-Headers": "application/json",
      Authorization: `Bearer ${SessionManager.getSessionData(ACCESS_TOKEN)}`,
      locale: SessionManager.getSessionData(SessionKeys.LANGUAGE_LOCALE)
        ? SessionManager.getSessionData(SessionKeys.LANGUAGE_LOCALE)
        : "en",
    };
  }
};

// http://162.144.64.126/
//Herbie_P:5xH6ikur7stPMKwlESo9JvI80ZqLNG
//Herbie_S: Hg1eN7uE5t89rWL32nwkPqmKJDSTpG
//TAXIMARK_P:Ei17Z08qlkCPATQHO4tDxSF6yBrw9N
//TAXIMARK_S: u817HTft6PZGopxivJVlDeFOnNXSAW
//TRANSI_P:  vSCQK9kREnPDpJ25qFxwL1HoNsluM0
//TRANSI_S:RVsmDxo0ZtIl9CO1LGYhUgX6FHeTw4
//APPORIO_P:EIAPt7JOD0uw3xsmynpNT96K5CL4GR
//APPORIO_S:Woghx5OG28ENmJQ0y9V4tlY3ZAwfR6
//TransuPpublic: JApQmr3RP0OISKMLywExD7vNk4qu98
//TransuPsecret: Sm1jyBZQIsKgZ6Oxk2NGpi4759RhLW
//AlminoP : TlORjKHCAJmItxiqMN0Q4Gy8urv6pD
//AlminoS :	pMyRIUBHLOjYiAVX5sqNJfFlev31CE
//apporioTestP:2CGO7Aq6PNy8uKxlMDvStn1oQrRiBm
//apporioTestS:h7ZUyR6V84NILQKrP9mpM3iC01jEku
//Ridefrica_P:upwZKDr36JQCmPyGTvBqikFxn0tNAO
//Ridefrica_S:2ZxI0UmyBXRHYFOp7uThPwSAZrfD1i
//Maya_P:HIEv5BoCmL38PR9wuFMixs7qrk6DAO
//Maya_S:ePp1tx3U9QhCjlgmRfFVLHwr8ivAuo
//Ryde_P:QGI7LN1SE0skBMxwKjOR89H3TPmiD6,
//Ryde_S:frOGAVXREmx5pDv2eoJF8HLCskSj1h
//varada_s:7ONktf84U3CKxPS5JAyR0vQXi921LH,
//varada_p:omtRisk3K7xp6S2GqLNTHC89MQrZFA
// VUPublic key - 4qHusx1DyRFSv6oCK3I70AJlkwEQin
// VUSecret Key - VGi5XZRf89BjkPgxwysIoT64AN1ten
// DNICEpublic: HGru8OK7toPLykJD0qsp1Bn5N2jx6R
// DNICEsecret : AqSgKf9yFJQYD3GUCHOtRNem71XPIw
//GINI_Pkey: QoBTLwriE68sRkpKj2S1yCIvD7F49n
//GINI_Skey: 2ZJnGj09L5p8N6kM1UluvIgYBO4fFm
//CarPop_PKEY:k8t9vim3T4LN0oqMRxnwGyS76DE1ZK
//CarPop_SKEY:FBCRA8uVIG9pvUjWETZJiDlwfMo4KZ
//taxitourist_public key - 3HE7RnGZrj5m064pilBJqADTI1MLNs
//taxitourist_secret key - RSJrOlCPGsejhIEwnyV96Bt8HA7vZg
//taxi+cv_public key - oOx8L2s1mtDjvKN4rQJ6Rip0qZGnHC
//taxi+cv_secret key - sXGpCNhmkluBeS0Fg1LOyQinJE9fD3

//----------------demov19----------------//
//ApporioTaxiDemo-P - 29qNTO6pKSGFDyw7AkoQvuxH3REPB4
//ApporioTaxiDemo-S - Wp6YJZAwNRXBDK2sOIqt4ekj1G80rC

//-----------delhiTrialV19---------//
//ApporioTaxi-P - O3CHoxPt2I1r6Z8ksi0RqJlGDu4N9m
//ApporioTaxi-S - i5SovWpXmCOZr41UZMPIKLflqN6syt

//------------delhiV19------------//
//Transi-P - CL15QF4P8kR2TyuJZlHGmx7SrwpOMD
//Transi-S - 2BVJhC13lWfD7jPOoMsgiAFxkTULSv
//Smash-P - 0N1wiMm6xkjoPpGOtRKHlqD2Zn9AFB
//Smash-S - 6qlvsRHkoYIVTx04rGQnyh8iXOueWL
//TaxiMarkP - N7A8oC1vTrw2JB69O30PQGtyKLsDSF
//TaxiMarkS - 6SBy0GuC3T5HmE7hMJtZrPengxlf4U
//RideFricaP- 8IOxw1T0AoC73iBJnN6K5MjEqpmP4k
//RideFricaS- Ihtr7R0eS2UZC1GqQf5iPmYnJ3ZgEF
//EasyTaxiNepalP - 5FTZ8vqjCtPBumHkpnL0wlGx73iNQO
//EasyTaxiNepalS - oP4W6FUnmifZQ8RSLJ5VCkIr90jyuG
// Maya_P: rLGJljT5BAtwP0Fu9QSZ6R2C4kvK3E
// Maya_S: knZVq2UXPSYoyQwiHv9sr6TFluBmCt

//-------obey-------//
// publicKey: "2M1QZnKqLHFIGl5s97wDCkoJruPv3B",
//secretKey: "ZKlxnyC6AiYOmXtNTv0P3ZLVhSQ2JF",

// ---------EASY taxi-------------------
// EasyTaxiNepalP: QCr7GH68ZI0nEmFsyvqAiu1KtOBD9o
// EasyTaxiNepalS: kvlEV1unTFwU9tZxCIopsA2YZ4r6JM
